/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.yna.co.kr/view/AKR20231130008300530?input=1195m"
  }, "새벽의 지진 경보")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://cloud.withgoogle.com/next"
  }, "Google Cloud Next 2024"), " 티케팅 시작"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.blizzcon.com/en-us/"
  }, "블리즈컨 2023"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=E09fsbMFUjI"
  }, "르세라핌 공연")), "\n"), "\n"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://devday.openai.com/"
  }, "OpenAI DevDay"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://platform.openai.com/docs/models/gpt-4-and-gpt-4-turbo"
  }, "GPT4 Turbo")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://platform.openai.com/docs/guides/text-generation/json-mode"
  }, "JSON 모드")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://platform.openai.com/docs/guides/text-generation/reproducible-outputs"
  }, "재생성가능한 출력")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://platform.openai.com/docs/assistants/overview"
  }, "Assistants API")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://platform.openai.com/docs/assistants/overview"
  }, "GPTs")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://platform.openai.com/playground?mode=assistant"
  }, "Assistants Playground")), "\n"), "\n"), "\n");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
